export const FULLSCREEN_MODE_ON = "FULLSCREEN_MODE_ON";
export const FULLSCREEN_MODE_OFF = "FULLSCREEN_MODE_OFF";
export const JOINING = "JOINING";
export const JOINED = "JOINED";
export const DISCONNECTED = "DISCONNECTED";
export const RECONNECTING = "RECONNECTING";
export const ENDED = "ENDED";
export const ERROR = "ERROR";

export const VIDEO_ON = "VIDEO_ON";
export const VIDEO_OFF = "VIDEO_OFF";
export const MIC_ON = "MIC_ON";
export const MIC_OFF = "MIC_OFF";

export const VIDEO_NO_PERMISSION = "VIDEO_NO_PERMISSION";
export const MIC_NO_PERMISSION = "MIC_NO_PERMISSION";
