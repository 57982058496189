import { ZoomMtg } from '@zoom/meetingsdk';

import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { ERROR, FULLSCREEN_MODE_OFF, FULLSCREEN_MODE_ON, JOINED, JOINING, MIC_NO_PERMISSION, MIC_OFF, MIC_ON, RECONNECTING, VIDEO_NO_PERMISSION, VIDEO_OFF, VIDEO_ON } from '../../constants';
import postWebViewData from '../../post-web-view-data';
import FirebaseService from '../../services/FirebaseService';


ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    Sentry.captureException(error);
    return Promise.reject(error);
  },
);

const LiveSession = () => {

  const token = process.env.REACT_APP_TOKEN;
  const [loaded, setLoaded] = useState(false);
  const [showRetry, setShowRetry] = useState(true);

  
  const { id } = useParams();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const userId = searchParams.get("user_id");
  const version = searchParams.get("version");

  useEffect(() => {
    if(!id || !userId) {
      handleError()
    }
    else {
      if(!version) {
        window.location.href = `${window.location.pathname}${window.location.search}&version=${process.env.REACT_APP_BUILD_VERSION}`
      }
      else {
        if(!loaded) {
          setTimeout(() => {
            FirebaseService.setUser(userId);
            getMeetingDetails();
            setLoaded(true)
          }, 1000)
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let portrait = window.matchMedia("(orientation: portrait)");
    portrait.addEventListener("change", function(e) {
      const meta = document.querySelector( "meta[name=viewport]" );
      meta.setAttribute( "content", "width=device-width, initial-scale=0, minimum-scale=0.9, maximum-scale=0.9" );
      meta.setAttribute( "content", "width=device-width, initial-scale=1, minimum-scale=1.0" );
    })
  }, [])

  const getMeetingDetails = async () => {
    
    const apiUrl = process.env.REACT_APP_API_URL || ''
    const authEndpoint = `${apiUrl}/live-sessions/${id}/signature`
   
    axios.post(authEndpoint, {
      user_id: userId,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json', 
        'Accept': 'application/json',
      },
    })
      .then((response) => {
        startMeeting(response.data.data)
      })
      .catch((error) => {
        FirebaseService.logEvent('zoom_alert', {
          item_id: userId,
          item_name: 'signature_failed'
        });
        console.error(error);
        handleError();
      });
  }

  const startMeeting = async ({ signature, meeting_id, meeting_password, username, email, sdk_key }) => {
    const leaveUrl = `${window.location.origin}/leave`;

    FirebaseService.logEvent('zoom_alert', {
      item_id: userId,
      item_name: 'initializing',
    });

    const trace = await FirebaseService.trace();
    trace?.start();
    ZoomMtg.init({
      leaveUrl,
      disableInvite: true,
      showMeetingHeader: false,
      videoHeader: false,
      audioPanelAlwaysOpen: true,
      patchJsMedia: true,
      meetingInfo: ['host', 'participant', 'topic', 'enctype'],
      success: (_success) => {

        ZoomMtg.join({
          signature,
          sdkKey: sdk_key,
          meetingNumber: meeting_id,
          passWord: meeting_password,
          userName: username,
          userEmail: email,
          success: (success) => {
            FirebaseService.logEvent('zoom_alert', {
              item_id: userId,
              item_name: 'joined',
            });
            postWebViewData(JOINED)
            setTimeout(() => {
              document.addEventListener("fullscreenchange", checkFullscreen);
            }, 5000)

            setTimeout(() => {
              const videoButton = document.getElementsByClassName("send-video-container__btn") && document.getElementsByClassName("send-video-container__btn")[0] ? document.getElementsByClassName("send-video-container__btn")[0] : null
              if(videoButton) {
                videoButton.onclick = handleVideoButtonClick
              }
              const micButton = document.getElementsByClassName("join-audio-container__btn") && document.getElementsByClassName("join-audio-container__btn")[0] ? document.getElementsByClassName("join-audio-container__btn")[0] : null
              if(micButton) {
                micButton.onclick = handleMicButtonClick
              }

            }, 2000)
            setTimeout(() => {
              const footerButton = document.getElementsByClassName('footer__btns-container')[0];

              footerButton.onmousedown = handleFocusClick
              
              // TODO: Fix later
              // const rightContainer = document.getElementById('wc-container-right')
              // if(rightContainer) {
              //   rightContainer.onclick = handleChatboxClick
              // }
              

            }, 2000)
            trace?.stop();
          },
          error: (error) => {
            FirebaseService.logEvent('zoom_alert', {
              item_id: userId,
              item_name: 'joining_failed'
            });
            trace?.stop();
          },
        });
      },
      error: (error) => {
        FirebaseService.logEvent('zoom_alert', {
          item_id: userId,
          item_name: 'initialization_failed'
        });
        handleError(false);
        trace?.stop();
      },
    });

    ZoomMtg.inMeetingServiceListener(
      'onMeetingStatus',
      (data) => {
        const { meetingStatus } = data;
        if (meetingStatus === 1) {
          postWebViewData(JOINING)
          FirebaseService.logEvent('zoom_alert', {
            item_id: userId,
            item_name: 'joining',
          });

          // Callback added for waiting screen exit
          const waitingScreenExitCallback = (e) => {
            e.preventDefault();
            FirebaseService.logEvent('zoom_alert', {
              item_id: userId,
              item_name: 'waiting_screen_exit_button_clicked'
            });
            setTimeout(() => {
              window.location.href = `${window.location.origin}/leave`
            }, 500)
          };

          // Callback added for preview screen exit
          const previewScreenExitCallback = (e) => {
            e.preventDefault();
            FirebaseService.logEvent('zoom_alert', {
              item_id: userId,
              item_name: 'preview_screen_exit_button_clicked'
            });
            setTimeout(() => {
              window.location.href = `${window.location.origin}/leave`
            }, 500)
          };
          
          let interval = setInterval(() => {
            if(document.getElementsByClassName('preview-root') && document.getElementsByClassName('preview-root')[0]) {

              if(document.getElementById('preview-av-video') && !document.querySelector('.mobile-waiting-room.on-hold-main.mobile-on-hold-main')) {
                document.getElementById('preview-av-video').setAttribute('id', 'preview-av-video-preview-room')

                document.getElementsByClassName('preview-root')[0]?.insertAdjacentHTML('beforeend', `<button class="preview-exit-btn" type="button" id="preview-screen-close-button"><div class="wr-exit-btn-icon" bis_skin_checked="1"><svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.414 8.586a2 2 0 0 0-2.828 2.828L17.172 20l-8.586 8.585a2 2 0 0 0 2.828 2.829L20 22.828l8.586 8.586a2 2 0 0 0 2.828-2.829L22.828 20l8.586-8.586a2 2 0 0 0-2.828-2.828L20 17.17l-8.586-8.585Z"></path></svg></div></button>`);
                document.getElementById('preview-screen-close-button').onclick = previewScreenExitCallback
              }
              else if(document.getElementById('preview-av-video') && document.querySelector('.mobile-waiting-room.on-hold-main.mobile-on-hold-main')) {
                FirebaseService.logEvent('zoom_alert', {
                  item_id: userId,
                  item_name: 'entered_wating_screen'
                });
                document.getElementsByClassName('preview-root')[0]?.insertAdjacentHTML('beforeend', `<button class="preview-exit-btn" type="button" id="waiting-screen-close-button"><div class="wr-exit-btn-icon" bis_skin_checked="1"><svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.414 8.586a2 2 0 0 0-2.828 2.828L17.172 20l-8.586 8.585a2 2 0 0 0 2.828 2.829L20 22.828l8.586 8.586a2 2 0 0 0 2.828-2.829L22.828 20l8.586-8.586a2 2 0 0 0-2.828-2.828L20 17.17l-8.586-8.585Z"></path></svg></div></button>`);
                document.getElementById('waiting-screen-close-button').onclick = waitingScreenExitCallback
              }
              
              if(document.getElementsByClassName('waiting-room-container') && document.getElementsByClassName('waiting-room-container')[0]) {
                const exitButton = document.querySelector('.waiting-room-container > .wr-information .leave-btn');
                FirebaseService.logEvent('zoom_alert', {
                  item_id: userId,
                  item_name: 'entered_wating_screen'
                });
                if(exitButton) {
                  exitButton.onclick = waitingScreenExitCallback
                }
              }

              if(document.getElementsByClassName('preview-agreement') && document.getElementsByClassName('preview-agreement')[0]) {
                document.getElementsByClassName('preview-agreement')[0].innerHTML = `<b>Welcome to the Live Class Preview!</b>

Toggle camera and microphone settings for a smooth experience.`;
                FirebaseService.logEvent('zoom_alert', {
                  item_id: userId,
                  item_name: 'entered_preview_screen'
                });
              }

              const videoButton = document.getElementById("preview-video-control-button") ? document.getElementById("preview-video-control-button") : null
              if(videoButton) {
                videoButton.onclick = handlePreviewVideoButtonClick
                if(!!document.querySelector('.zm-btn.preview-join-button')) {
                  document.querySelector('.zm-btn.preview-join-button').innerText = "Join Live Class"
                  document.querySelector('.zm-btn.preview-join-button').onclick = () => {
                    FirebaseService.logEvent('zoom_alert', {
                      item_id: userId,
                      item_name: 'preview_screen_join_button_clicked'
                    });
                  }
                }
              }
              const micButton = document.getElementById("preview-audio-control-button") ? document.getElementById("preview-audio-control-button") : null
              if(micButton) {
                micButton.onclick = handlePreviewMicButtonClick
                // Refresh page after 20 seconds if permission popup not opening
                setTimeout(() => {
                  if(micButton.querySelector('svg.spinner')) {
                    const retried = Number(window.sessionStorage.getItem('retry')) || 0;
                    if(retried < 5) {
                      window.sessionStorage.setItem('retry', retried + 1)
                      window.location.reload();
                    }
                    else {
                      window.sessionStorage.removeItem('retry')
                    }
                  }
                }, 20000)
              }
              clearInterval(interval);
            }
          }, 100) 
        }
        else if (meetingStatus === 3) {
          postWebViewData(JOINING)
          FirebaseService.logEvent('zoom_alert', {
            item_id: userId,
            item_name: 'leave',
          });
        }
        else if (meetingStatus === 4) {
          postWebViewData(RECONNECTING)
          FirebaseService.logEvent('zoom_alert', {
            item_id: userId,
            item_name: 'reconnecting',
          });
        }
      }
    );
  }

  const handleError = (leave = true) => {
    postWebViewData(ERROR)
    if(leave)
      setTimeout(() => {
        window.location.href=`${window.location.origin}/leave`
      }, 100)
    else {
      document.getElementById('root').style = "z-index: 1; postition: relative;";
      setShowRetry(true)
    }
  }
  const checkFullscreen = () => {
      if(!document.fullscreenElement) {
        postWebViewData(FULLSCREEN_MODE_OFF)
      }
      else {
        postWebViewData(FULLSCREEN_MODE_ON)
      }
  }

  const errorTagVideo = '<path fill-rule="evenodd" clip-rule="evenodd" d="M23.257 20.765A1.5 1.5 0 0 0 24.565 23h10.87a1.5 1.5 0 0 0 1.308-2.235l-5.436-9.663a1.5 1.5 0 0 0-2.614 0l-5.436 9.663ZM29.35 17.6v-2.95a.65.65 0 1 1 1.3 0v2.95a.65.65 0 0 1-1.3 0Zm-.1 2.65a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0Z" fill="#FAAC2A"></path>';
  const errorTagMic = '<path fill-rule="evenodd" clip-rule="evenodd" d="M17.257 20.765A1.5 1.5 0 0 0 18.565 23h10.87a1.5 1.5 0 0 0 1.308-2.235l-5.436-9.663a1.5 1.5 0 0 0-2.614 0l-5.436 9.663ZM23.35 17.6v-2.95a.65.65 0 0 1 1.3 0v2.95a.65.65 0 0 1-1.3 0Zm-.1 2.65a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0Z" fill="#FAAC2A"></path>';

  const handleVideoButtonClick = () => {

      const videoButton = document.getElementsByClassName("send-video-container__btn") && document.getElementsByClassName("send-video-container__btn")[0] ? document.getElementsByClassName("send-video-container__btn")[0] : null
      
      let videoNotificationButton = document.getElementsByClassName("notification-message-wrap__txt") && document.getElementsByClassName("notification-message-wrap__txt")[0] ? document.getElementsByClassName("notification-message-wrap__txt")[0] : null
      if((videoButton.children && videoButton.children[0] && videoButton.children[0].children[0] && videoButton.children[0].children[0] && videoButton.children[0].children[0].children[1]?.outerHTML === errorTagVideo) || (videoNotificationButton && videoNotificationButton.innerHTML.includes(`Enable camera access in your browser’s address bar and refresh the page`))) {
        postWebViewData(VIDEO_NO_PERMISSION)
        return;
      }

      if(videoButton.ariaLabel === "stop my video") {
        postWebViewData(VIDEO_OFF)
      }
      else {
        postWebViewData(VIDEO_ON)
      }
    setTimeout(() => {
      videoNotificationButton = document.getElementsByClassName("notification-message-wrap__txt") && document.getElementsByClassName("notification-message-wrap__txt")[0] ? document.getElementsByClassName("notification-message-wrap__txt")[0] : null
      if((videoButton.children && videoButton.children[0] && videoButton.children[0].children[0] && videoButton.children[0].children[0] && videoButton.children[0].children[0].children[1]?.outerHTML === errorTagVideo) || (videoNotificationButton && videoNotificationButton.innerHTML.includes(`Enable camera access in your browser’s address bar and refresh the page`))) {
        postWebViewData(VIDEO_NO_PERMISSION)
        return;
      }
    }, 1000)
  }
  
  // TODO: Fix later
  // const handleChatboxClick = (e) => {
  //   if(Array.from(e.target.classList).includes('chat-rtf-box__editor-wrapper')) {
  //     document.getElementsByClassName('chat-rtf-box__editor-wrapper')[0].querySelector('div.tiptap').setAttribute('contenteditable', true)
  //     document.getElementsByClassName('chat-rtf-box__editor-wrapper')[0].querySelector('div.tiptap').focus();
  //   } else if(e.target && e.target.parentElement) {
  //     if(Array.from(e.target.parentElement.classList).includes('chat-rtf-box__editor-wrapper')) {
  //       document.getElementsByClassName('chat-rtf-box__editor-wrapper')[0].querySelector('div.tiptap').setAttribute('contenteditable', true)
  //       document.getElementsByClassName('chat-rtf-box__editor-wrapper')[0].querySelector('div.tiptap').focus();
  //     }
  //   }
    
  // };

  const handleFocusClick = (e) => {
    if(e.target instanceof SVGElement) {
      let svgElement = e.target;
      if(e.target.tagName === 'path') {
        svgElement = e.target.parentElement;
      }
      let hoveredClass = svgElement.className?.baseVal ?? svgElement.className;
      
      if(hoveredClass.includes('SvgMore')) {
        hoveredClass = hoveredClass.replace('Hovered', '')
      };

      if(hoveredClass) {
        const element = document.querySelector(`svg.${hoveredClass}`)?.parentElement?.parentElement
        if(element && element.click) {
          element.click();
        }
      }
    }
  }

  const handleMicButtonClick = () => {
    const micButton = document.getElementsByClassName("join-audio-container__btn") && document.getElementsByClassName("join-audio-container__btn")[0] ? document.getElementsByClassName("join-audio-container__btn")[0] : null
    if(micButton.children && micButton.children[0] && micButton.children[0].children[0] && micButton.children[0].children[0] && micButton.children[0].children[0].children[1]?.outerHTML === errorTagMic) {
      postWebViewData(MIC_NO_PERMISSION)
      return;
    }
    if(micButton.ariaLabel === "mute my microphone") {
      postWebViewData(MIC_OFF)
    }
    else {
      postWebViewData(MIC_ON)
    }
  }

  const handlePreviewVideoButtonClick = () => {
    const videoButton = document.getElementById("preview-video-control-button") ? document.getElementById("preview-video-control-button") : null
    if(videoButton.children && videoButton.children[0] && videoButton.children[0].children[1] && videoButton.children[0].children[1].outerHTML === errorTagVideo) {
      postWebViewData(VIDEO_NO_PERMISSION)
      return;
    }
    if(videoButton.ariaLabel === "Stop Video") {
      postWebViewData(VIDEO_OFF)
    }
    else if(videoButton.ariaLabel === "Start Video") {
      postWebViewData(VIDEO_ON)
    }
  }

  const handlePreviewMicButtonClick = () => {
    const micButton = document.getElementById("preview-audio-control-button") ? document.getElementById("preview-audio-control-button") : null
    if(micButton.children && micButton.children[0] && micButton.children[0].children[1] && micButton.children[0].children[1].outerHTML === errorTagMic) {
      postWebViewData(MIC_NO_PERMISSION)
      return;
    }
    if(micButton.ariaLabel === "Mute") {
      postWebViewData(MIC_OFF)
    }
    else {
      postWebViewData(MIC_ON)
    }
  }

  const handleRetry = () => {
    FirebaseService.logEvent('zoom_alert', {
      item_id: userId,
      item_name: 'initialize_failed_retry',
    });
    window.location.reload()
  }

  return (
    <>
    <div></div>
      {showRetry && <div className="ReactModalPortal">
        <div className="ReactModal__Overlay ReactModal__Overlay--after-open" style={{ position: "fixed", inset: "0px", backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 9999 }}>
          <div style={{ position: "absolute", inset: "50% auto auto 50%", border: "medium", background: "transparent", overflow: "auto", borderRadius: "4px", outline: "none", padding: "0px", transform: "translate(-50%, -50%)", zIndex: 1 }} className="ReactModal__Content ReactModal__Content--after-open" role="dialog" aria-label="Joining Meeting Timeout or Browser restriction" aria-modal="true">
            <div className="ec-modal ec-modal-legacy connect-error-dialog">
              <div className="ec-modal-body">
                <div className="ec-modal-body-title">
                  <h2 className="ec-modal-body-title">Couldn't join the meeting due to some Browser issues</h2>
                </div>
                <div className="ec-modal-body-content">
                  <p className="content ec-modal-body-content">Please try again!</p>
                </div>
              </div>
              <div className="ec-modal-footer">
                <div className="ec-modal-footer-default">
                  <div className="ec-modal-footer-default-actions">
                    <button type="button" className="ec-btn ec-btn-legacy ec-btn--primary ec-btn__outline--blue" aria-label="Retry"
                      onClick={handleRetry}
                    >
                      Retry
                      <span style={{ display: "none" }} className="loading"></span>
                    </button>
                    <button type="button" className="zm-btn zm-btn-legacy zm-btn--default zm-btn__outline--blue" aria-label=""
                      onClick={handleError}
                    >
                      Leave
                      <span style={{ display: "none" }} className="loading"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
  )
}

export default LiveSession
