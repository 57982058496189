import { BrowserRouter, Route, Switch } from "react-router-dom";
import './App.css';
import NoPage from './pages/NoPage';
import Leave from './pages/Leave';
import LiveSession from './pages/LiveSession';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';

// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route);
const history = createBrowserHistory();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment: process.env.REACT_APP_ENV,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <SentryRoute path="/live-sessions/:id">
          <LiveSession />
        </SentryRoute>
        <SentryRoute path="/leave">
          <Leave />
        </SentryRoute>
        <SentryRoute path="/">
          <NoPage />
        </SentryRoute>
        <SentryRoute path="*">
          <NoPage />
        </SentryRoute>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
