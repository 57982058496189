import React, { useEffect } from 'react'
import { ENDED } from '../constants';
import postWebViewData from '../post-web-view-data';

const Leave = () => {
  
  useEffect(() => {
    postWebViewData(ENDED)
  }, []);

  return (
    <div></div>
  )
}

export default Leave