// import analytics from '@react-native-firebase/analytics';
import {
  getAnalytics,
  logEvent,
  setUserId,
} from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { trace } from "firebase/performance";

import { getPerformance } from "firebase/performance";


import FirebaseConfig from './firebaseConfig';

let analyticsGlobal = null;
let performanceGlobal = null;

const initializeFirebase = () => {
  if (!FirebaseConfig.apiKey) return [null];

  if (analyticsGlobal || performanceGlobal) return [analyticsGlobal, performanceGlobal];

  // Initialize Firebase
  const app = initializeApp(FirebaseConfig);
  if (!app) return [null];

  // Initialize Analytics and get a reference to the service
  const analytics = getAnalytics(app);
  analyticsGlobal = analytics;

  // Initialize Performance Monitoring and get a reference to the service
  const performance = getPerformance(app);
  performanceGlobal = performance;

  performance.dataCollectionEnabled = true;
  performance.instrumentationEnabled = true;

  return [analytics, performance];
};

const FirebaseService = {
  setUser: async (id) => {
    const [analytics] = initializeFirebase();
    if (!analytics) return;
    setUserId(analytics, `${id}`);
  },

  logEvent: async (name, params) => {
    const [analytics] = initializeFirebase();
    if (!analytics) return;
    Object.keys(params).forEach((p) => {
      // eslint-disable-next-line no-param-reassign
      params[p] = String(params[p]);
    });

    await logEvent(analytics, name, params);
  },
  
  trace: () => {
    const [_analytics, performance] = initializeFirebase();
    if (!_analytics || !performance) return;
    return trace(performance, "userJoining");
  }

};

export default FirebaseService;
